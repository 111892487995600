import _ from "../node_modules/underscore/underscore"
import {getListByDivision, getListByMarket} from "./index";



let company;
export function filterFunction() {
    company = localStorage.getItem('companyName');
    let divisionarray = [];
    let plantarray = [];
    let dmaarray = [];
    let divisonarrayunique = [];
    let dmaarrayunique = [];
    let plantarrayunique =[];
    const querySelector = $('#querySelector');
    const plantSelector = $('#plantSelector');
    const divisionSelector = $('#divisionselector');
    querySelector.select2({
        placeholder: "Select Market or Division",
        data: [{
            "id": 1,
            "text": "Divisions"
        }, {
            "id": 2,
            "text": "DMA Markets"
        }]
    });
    plantSelector.select2({
        placeholder: company
    });


//division & market filters from imp_comp schema
    function callMediaDma() {
        $("#loading-spinner").show();
        // settings for ajax call
        let settings = {
            "async": true,
            "crossDomain": true,
            "url": process.env.appurl + "api/getdivisionsmarkets",
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
            "data":{"parent_account_name":company},
            "dataType":"json"
        };
        //get all arrays and deduplicate them
        $.ajax(settings).done(function (response) {
                $("#loading-spinner").hide();
                $.each(response, function (index, value) {
                    if (value['parent_account_name']) {

                        plantarray.push(
                            {
                                "id": index,
                                "text": value['parent_account_name']
                            }
                        );
                        divisionarray.push(
                            {
                                "id": index,
                                "text": value['account_name']
                            }
                        );
                        dmaarray.push(
                            {
                                "id": index,
                                "text": value['dma_name']
                            }
                        );
                    }
                });
                plantarrayunique.push(_.uniq(plantarray, function (x) {
                    return x.text;
                }));
                divisonarrayunique.push(_.uniq(divisionarray, function (x) {
                    return x.text;
                }));
                dmaarrayunique.push(_.uniq(dmaarray, function (x) {
                    return x.text;
                }));

                //sort function the arrays alphabetically for the Select2 dropdowns
                function compare(a, b) {
                    // console.log(a,b)
                    // Use toUpperCase() to ignore character casing
                    const A = a.text.toUpperCase();
                    const B = b.text.toUpperCase();

                    let comparison = 0;
                    if (A > B) {
                        comparison = 1;
                    } else if (A < B) {
                        comparison = -1;
                    }
                    return comparison;
                }
                //sort plants first
                plantarrayunique[0].sort(compare);
                plantSelector.select2({
                    placeholder: company,
                    data: plantarrayunique[0],
                });
                //if company is geopath, go and populate with all companies and prepare to rerun
                if (company === 'Geopath'){
                    plantSelector.on('select2:select', function () {
                        const selectedPlant = plantSelector.select2('data');
                        company = selectedPlant[0]['text'];
                        plantSelector.select2({
                            placeholder:company
                        });
                        divisonarrayunique =[];
                        divisionarray = [];
                        divisionSelector.html('').select2({
                            data: [{
                                id: '',
                                text: ''
                            }]
                        });
                        dmaarrayunique =[];
                        dmaarray = [];
                        //once a plant is selected proceed as normal with that plant
                        getOperatorSumStats(company);
                        getOperatorIssues(company);
                        callMediaDma();
                    })
                }
                //if the company is not geopath proceed as usual and sort/populate divisions or dmas
                else {
                    // console.log(divisonarrayunique[0]);
                    // divisonarrayunique[0].sort(compare);
                    // dmaarrayunique[0].sort(compare);
                    setMediaDma()
                }
            }
        )
    }
    //select the options for dma/division and run the queries to get all inventory for those options
    function setMediaDma() {
        const queryTypeSel = querySelector.select2('data');
        const queryType = queryTypeSel[0]['text'];
        if (queryType === 'Divisions') {
            divisionSelector.select2({
                placeholder: "Please select a Division",
                data: divisonarrayunique[0]
            });
        }
        else {
            divisionSelector.select2({
                placeholder: "Please select a DMA",
                data: dmaarrayunique[0]
            });
        }
    }
    querySelector.on('select2:select', function () {
        divisionSelector.html('').select2({
            data: [{
                id: '',
                text: ''
            }]
        });
        if (divisionarray.length === 0){
            callMediaDma()
        }
        setMediaDma();
    });
    divisionSelector.on('select2:select', function () {
        if (company === 'Geopath'){
            let compName = plantSelector.select2('data');
            company = compName[0]['text']
        }
        const queryTypeSel = querySelector.select2('data');
        const queryType = queryTypeSel[0]['text'];
        if (queryType === 'Divisions') {
            const division1 = divisionSelector.select2('data');
            const account_name = division1[0]['text'];
            let formData = {
                "account_name": account_name,
                "parent_account_name": company,
            };
            getListByDivision(formData)
        }
        else {
            const dma1 = divisionSelector.select2('data');
            const dma_name = dma1[0]['text'];
            let formData = {
                "dma_name": dma_name,
                "parent_account_name": company,
            };
            getListByMarket(formData)
        }
    })
}

// app.js
import Auth0Lock from 'auth0-lock'
import {filterFunction} from "./filter";


let domain = 'geopath.auth0.com';
let clientID = 'J6yzVwCEVhgWXHq1fxN9SkTC1bo0sLyv';

window.addEventListener('load', function() {
    const content = document.querySelector('.container-fluid');
    const navbar = document.querySelector('.navbar-nav.mr-auto');
    const loadingSpinner = document.getElementById('loading');
    content.style.display = 'none';

    const options = {
        theme:{
            logo: 'https://assets.geopath.io/Atlas/geopathgray.svg',
            primaryColor:'#00839A',
        },
        languageDictionary: {
            emailInputPlaceholder: "Geopath Account Email",
            title: "Geopath Comparison"
        },
        autoclose: true,

        auth: {
            redirect: false,
            responseType: 'token id_token',
            audience: 'https://comparison.geopath.org/api',
            params: {
                scope:'openid profile email'
            }
        }
    };

    const lock = new Auth0Lock(clientID, domain,options);
    lock.on('authenticated', function (authResult) {
        window.userEmail = authResult.idTokenPayload["email"];
        window.companyName = authResult.idTokenPayload["https://comparison.geopath.org/app"].company.name;
        let memberType = authResult.idTokenPayload["https://comparison.geopath.org/app"].memberType;
        localStorage.setItem('companyName',companyName);
        localStorage.setItem('userEmail',userEmail);
        if (memberType === 'Plant') {
            if (authResult && authResult.accessToken && authResult.idToken) {
                setSession(authResult);
                displayButtons();
            }
        }
        else if (companyName === 'Geopath') {
            if (authResult && authResult.accessToken && authResult.idToken) {
                setSession(authResult);
                displayButtons();
            }
        }
        else if (companyName === 'Intermx') {
            if (authResult && authResult.accessToken && authResult.idToken) {
                setSession(authResult);
                displayButtons();
            }
        }
        else {
            alert('Login Failed, Please double check your credentials and try again. If this issue persists, please email geekout@geopath.org');
            let content = document.querySelector('.container-fluid');
            content.innerHTML = '<br><br><br><br><h2>Sorry You Do not have access to this tool</h2>';
            content.style.display = 'inline-block';
            displayButtons();
        }
    });

    lock.on('authorization_error', function(err) {
        alert('Login Failed, Please double check your credentials and try again. If this issue persists, please email geekout@geopath.org');
        displayButtons();
    });

    // buttons and event listeners
    const loginBtn = document.getElementById('btn-login');
    const logoutBtn = document.getElementById('btn-logout');
    loginBtn.addEventListener('click', login);
    logoutBtn.addEventListener('click', logout);

    function login() {
        lock.show();
    }

    function setSession(authResult) {
        window.userId= authResult.idTokenPayload.sub;
        // Set the time that the access token will expire at
        let expiresAt = JSON.stringify(
            authResult.expiresIn * 1000 + new Date().getTime()
        );
        localStorage.setItem('companyName',companyName);
        localStorage.setItem('userEmail',userEmail);
        localStorage.setItem('userID',userId);
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
    }

    // Remove tokens and expiry time from localStorage
    function logout() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        localStorage.removeItem('userID');
        localStorage.removeItem('companyName');
        localStorage.removeItem('userEmail');
        content.style.display = 'none';
        navbar.style.display = 'none';
        loginBtn.style.display = 'inline-block';
        logoutBtn.style.display = 'none';
        lock.show()
    }

    // Check whether the current time is past the access token's expiry time
    function isAuthenticated() {
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }

    function displayButtons() {
        if (isAuthenticated()) {
            localStorage.getItem('companyName');
            localStorage.getItem('userEmail');
            localStorage.getItem('userID');
            localStorage.getItem('access_token');
            filterFunction();
            getOperatorSumStats(localStorage.getItem('companyName'));
            getOperatorIssues(localStorage.getItem('companyName'));

            navbar.style.display = 'inline-block';
            loginBtn.style.display = 'none';
            logoutBtn.style.display = 'inline-block';
            content.style.display = 'block';
        } else {
            lock.show();
        }
    }
    displayButtons();
});